<template>
  <n-spin :show="loading">
    <span>
      <n-divider title-placement="left" v-if="!summarized">{{
        showOwnerPayout ? "Renter Paid" : "Price Details"
      }}</n-divider>
      <n-grid :cols="summarized || fullscreen ? '1' : '1 s:3'" responsive="screen"
        ><n-gi span="1" offset="0 s:1"
          ><n-space justify="space-between" v-if="summarized">
            <span>{{ t("rent.detail.prices.labels.total") }}</span>
            <span>{{ floatToCurrency(rent.totalCost) }}</span>
          </n-space>
          <span v-else>
            <n-space justify="space-between">
              <span>{{
                `   ${floatToCurrency(rent.pricePerDay)} x ${rent.requestedDays} ${t(
                  "commons.labels.day",
                  rent.requestedDays
                )}`
              }}</span>
              <span>{{ floatToCurrency(rent.baseCost) }}</span>
            </n-space>
            <n-space
              justify="space-between"
              v-if="rent.requestedDays < rent.executedDays"
            >
              <span
                ><n-text strong>{{
                  `   ${floatToCurrency(rent.pricePerDay)} x ${
                    rent.executedDays - rent.requestedDays
                  } ${t(
                    "rent.detail.prices.labels.extraDay",
                    rent.executedDays - rent.requestedDays
                  )}`
                }}</n-text></span
              >
              <span
                ><n-text strong>{{ floatToCurrency(rent.extraDaysCost) }}</n-text></span
              >
            </n-space>
            <n-space justify="space-between" v-if="rent.discount > 0">
              <n-text type="success">{{
                t("rent.detail.prices.labels.discount")
              }}</n-text>
              <n-text type="success">-{{ floatToCurrency(rent.discount) }}</n-text>
            </n-space>
            <n-space justify="space-between">
              <span>{{ t("rent.detail.prices.labels.platformFee") }}</span>
              <span>{{ floatToCurrency(rent.renterServiceFee) }}</span>
            </n-space>
            <n-space justify="space-between" v-if="rent.tax > 0">
              <span>{{ t("rent.detail.prices.labels.tax") }}</span>
              <span>{{ floatToCurrency(rent.tax) }}</span>
            </n-space>
            <n-divider />
            <n-space justify="space-between">
              <span>{{ t("rent.detail.prices.labels.total") }}</span>
              <span>{{ floatToCurrency(rent.totalCost) }}</span>
            </n-space>
            <n-space justify="space-between" v-if="cancelationFeeFormatted">
              <span>{{ t("rent.detail.prices.labels.cancelationFee") }}</span>
              <span>-{{ cancelationFeeFormatted }}</span>
            </n-space>
            <n-space justify="space-between" v-if="totalPayedFormatted">
              <span>{{ t("rent.detail.prices.labels.payed") }}</span>
              <span>{{ totalPayedFormatted }}</span>
            </n-space>
            <n-space justify="space-between" v-if="refundAmountFormatted">
              <span>{{ t("rent.detail.prices.labels.refundAmount") }}</span>
              <span>{{ refundAmountFormatted }}</span>
            </n-space>
            <n-space justify="space-between" v-if="pendingToPayFormatted">
              <n-text type="error">{{
                rent.totalPayed > rent.totalCost
                  ? t("rent.detail.prices.labels.amountToRefund")
                  : t("rent.detail.prices.labels.pendingBalance")
              }}</n-text>
              <n-text type="error">{{ pendingToPayFormatted }}</n-text>
            </n-space>
            <n-divider v-if="rent.deposit > 0" title-placement="left">{{
              t("rent.detail.prices.labels.refundableDeposit")
            }}</n-divider>
            <n-space v-if="rent.deposit > 0" justify="space-between">
              <span>{{ t("rent.detail.prices.labels.deposit") }}</span>
              <span>{{ floatToCurrency(rent.deposit) }}</span>
            </n-space>
            <n-space
              v-if="
                rent.depositTransaction?.balance &&
                rent.depositTransaction?.balance !== rent.deposit
              "
              justify="space-between"
            >
              <span>{{ t("rent.detail.prices.labels.retainedForDamages") }}</span>
              <span>- {{ floatToCurrency(rent.depositTransaction?.balance) }}</span>
            </n-space>
            <n-space
              v-if="
                rent.depositTransaction?.balance &&
                rent.depositTransaction?.balance !== rent.deposit
              "
              justify="space-between"
            >
              <span>{{ t("rent.detail.prices.labels.total") }}</span>
              <span>{{
                floatToCurrency(rent.deposit - rent.depositTransaction?.balance)
              }}</span>
            </n-space></span
          ></n-gi
        ></n-grid
      >
      <n-divider v-if="showOwnerPayout" title-placement="left">{{
        t("rent.detail.prices.labels.ownerPayout")
      }}</n-divider>
      <n-grid
        v-if="showOwnerPayout"
        :cols="summarized || fullscreen ? '1' : '1 s:3'"
        responsive="screen"
        ><n-gi span="1" offset="0 s:1"
          ><n-space justify="space-between" v-if="summarized">
            <span>{{ t("rent.detail.prices.labels.totalPayout") }}</span>
            <span>{{ floatToCurrency(rent.subtotal - rent.ownerServiceFee) }}</span>
          </n-space>
          <span v-else>
            <n-space justify="space-between">
              <span>{{
                `   ${floatToCurrency(rent.pricePerDay)} x ${
                  rent.executedDays || rent.requestedDays
                } Day${rent.executedDays || rent.requestedDays > 1 ? "s" : ""}`
              }}</span>
              <span>{{ floatToCurrency(rent.subtotal) }}</span>
            </n-space>
            <n-space justify="space-between">
              <span>{{ t("rent.detail.prices.labels.platformFee") }}</span>
              <span>- {{ floatToCurrency(rent.ownerServiceFee) }}</span>
            </n-space>

            <n-divider />
            <n-space justify="space-between">
              <span>{{ t("rent.detail.prices.labels.totalPayout") }}</span>
              <span>{{ floatToCurrency(rent.subtotal - rent.ownerServiceFee) }}</span>
            </n-space>
          </span></n-gi
        ></n-grid
      >
      <n-divider
        title-placement="left"
        v-if="!showOwnerPayout && !summarized && payments"
        >{{ t("rent.detail.prices.labels.payments") }}</n-divider
      >
      <n-grid
        v-if="!showOwnerPayout"
        :cols="summarized || fullscreen ? '1' : '1 s:3'"
        responsive="screen"
        ><n-gi span="1" offset="0 s:1"
          ><n-space
            justify="space-between"
            v-for="payment in payments"
            :key="payment.transactionId"
          >
            <span>{{ payment.cardType }} XX{{ payment.lastTwo }}</span>
            <span>{{ payment.amount }}</span>
          </n-space>
          <span
            v-for="payment in payments?.filter((payment) => payment.reverseHistory)"
            :key="payment.transactionId"
            ><n-space
              justify="space-between"
              v-for="reverse in payment.reverseHistory"
              :key="reverse.id"
            >
              <span
                >{{ payment.cardType }} XX{{ payment.lastTwo }} ({{
                  t("rent.detail.prices.labels.reverse")
                }})</span
              >
              <span>-{{ reverse.amount ? reverse.amount.value : payment.amount }}</span>
            </n-space></span
          >
        </n-gi></n-grid
      >
    </span>
  </n-spin>
</template>
<script>
import { defineComponent, computed, onMounted, toRefs } from "vue";
import responsive from "@/mixins/responsive";
import { floatToCurrency } from "@/shared/utils";
import { NDivider, NGrid, NGi, NSpace, NText, NSpin } from "naive-ui";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  mixins: [responsive],
  name: "PriceDetails",
  components: { NDivider, NGrid, NGi, NSpace, NText, NSpin },
  props: {
    rent: { type: Object, required: true },
    bordered: { type: Boolean, required: false, default: false },
    summarized: { type: Boolean, default: false },
    fullscreen: { type: Boolean, default: false },
    loading: { type: Boolean, required: false, default: false },
  },
  emits: ["onmounted"],
  setup(props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const store = useStore();
    const rentRef = toRefs(props).rent;
    const currentUserRef = computed(() => store.getters["auth/user_id"]);
    onMounted(() => {
      emit("onmounted");
    });

    return {
      t,
      floatToCurrency,
      showOwnerPayout: computed(() => currentUserRef.value !== rentRef.value.user),
      totalPayedFormatted: computed(() =>
        rentRef.value.paymentsInfo && rentRef.value.totalCost !== rentRef.value.totalPayed
          ? floatToCurrency(rentRef.value.totalPayed)
          : null
      ),
      pendingToPayFormatted: computed(() =>
        rentRef.value.state !== "UNPAID" &&
        rentRef.value.totalCost !== rentRef.value.totalPayed
          ? floatToCurrency(Math.abs(rentRef.value.totalCost - rentRef.value.totalPayed))
          : null
      ),
      refundAmountFormatted: computed(() =>
        rentRef.value.state === "CANCELLED" &&
        rentRef.value.totalCost !== rentRef.value.totalPayed
          ? floatToCurrency(rentRef.value.totalCost - rentRef.value.totalPayed)
          : null
      ),
      cancelationFeeFormatted: computed(() =>
        rentRef.value.state === "CANCELLED" &&
        rentRef.value.totalCost === rentRef.value.totalPayed
          ? floatToCurrency(rentRef.value.totalPayed)
          : null
      ),
      payments: computed(() =>
        rentRef.value.paymentsInfo
          ?.filter((payment) =>
            [
              "PENDING",
              "AUTHORIZED",
              "SUBMITTED_FOR_SETTLEMENT",
              "SETTLING",
              "SETTLED",
            ].includes(payment.status)
          )
          .map((payment) => {
            return {
              ...payment,
              amount: floatToCurrency(payment.amount),
              reverseHistory: payment.reverseHistory?.map((reverse) => {
                if (reverse.amount) {
                  reverse.amount = { value: floatToCurrency(reverse.amount.value) };
                }
                return reverse;
              }),
            };
          })
      ),
    };
  },
});
</script>

<style lang="scss" scoped>
.n-text.n-text--strong {
  font-weight: 550;
}
</style>
